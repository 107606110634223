import { Vibration } from "@awesome-cordova-plugins/vibration/ngx";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouteReuseStrategy } from "@angular/router";

import { IonicModule, IonicRouteStrategy } from "@ionic/angular";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ThemeChangerComponent } from "./shared/theme-changer/theme-changer.component";
import { IonicStorageModule, Storage } from "@ionic/storage-angular";
import { Drivers } from "@ionic/storage";
import { CloudSettings } from "@awesome-cordova-plugins/cloud-settings/ngx";
import { MobileAccessibility } from "@awesome-cordova-plugins/mobile-accessibility/ngx";
import { Keyboard } from "@awesome-cordova-plugins/keyboard/ngx";
import { ScreenOrientation } from "@awesome-cordova-plugins/screen-orientation/ngx";
import { StorageManagerService } from "./shared/services/storage-manager.service";
import { AppInitializerModule } from "./app-initializer.module";
import { CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { register } from "swiper/element/bundle";
import { SQLite } from "@awesome-cordova-plugins/sqlite/ngx";

register();
@NgModule({
  declarations: [AppComponent, ThemeChangerComponent],
  imports: [
    // preloads storage manager init function
    AppInitializerModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    IonicStorageModule.forRoot({
      name: '_ionicstorage',
      driverOrder: [
        Drivers.IndexedDB,
        Drivers.LocalStorage,
      ],
    }),
    IonicModule.forRoot({ innerHTMLTemplatesEnabled: true }),
  ],
  providers: [
    StorageManagerService,
    ThemeChangerComponent,
    CloudSettings,
    Vibration,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    MobileAccessibility,
    Keyboard,
    ScreenOrientation,
    Storage,
    SQLite,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
