<ion-app>
  <swiper-container
    [@slideInFromRight]="storageManager.getFromStorage('isFirstTime')"
    *ngIf="storageManager.getFromStorage('isFirstTime')"
    class="mySwiper"
    pagination="true"
    (slidechange)="updateIndex($event)"
  >
    <swiper-slide class="ion-padding slide-margins">
      <h1>Welcome</h1>
      <p>This app contains four tabs</p>
      <p>Swipe for a quick introduction</p>
      <ion-icon
        size
        class="onboarding-arrow"
        size="large"
        name="arrow-back-outline"
      ></ion-icon>
    </swiper-slide>
    <swiper-slide class="slide-margins">
      <h1>Calculator Tab</h1>
      <p class="ion-padding">
        Here you enter size, shape, quantity, and more.
      </p>

      <div class="text-with-icon">
        <p>
          Tooltips <ion-icon
          slot="icon-only"
          name="information-circle"
          class="fake-tooltip-icon"
          color="medium"
        ></ion-icon
        > are included
        </p>
      </div>
      <p class="no-top-margin">in each section to help.</p>

      <div>
        <ion-button expand="block" disabled
          >Recipes<ion-icon slot="end" name="list-outline"></ion-icon
        ></ion-button>
      </div>

      <div>
        <ion-icon class="onboarding-arrow" name="arrow-up-outline"></ion-icon>
      </div>
      <p class="text-below-button">
        Look for this button<br />to pick out a premade recipe.
      </p>
      <p >
        There is also a Simple Mode for newcomers.<br />Easy, no fuss.
      </p>
    </swiper-slide>
    <swiper-slide class="slide-margins"
      ><h1>Recipe Tab</h1>
      <p >
        Here is where you see your recipe and instructions.
        <br /><br />
        To get started all you need is a kitchen scale, bowls, and
        basic dough ingredients.
      </p></swiper-slide
    >
    <swiper-slide class="slide-margins" *ngIf="!isFreemium"
      ><h1>Saved Tab</h1>
      <p >
        Here you can save and manage all of your recipes.
      </p></swiper-slide
    >
    <swiper-slide class="slide-margins"
      ><h1>Misc Tab</h1>
      <p >
        Here you can see app information, tips and tricks, and app settings.
      </p>
      <p >That's it! Let's get started.</p>
      <ion-button
        (click)="storageManager.toggleStorageBool('isFirstTime', true, false)"
        >Get started!</ion-button
      >
    </swiper-slide>
  </swiper-container>

  <div
    *ngIf="storageManager.getFromStorage('isFirstTime')"
    (click)="storageManager.toggleStorageBool('isFirstTime', true, false)"
  >
    <ion-icon
      class="onboarding-arrow close-onboarding-btn"
      size="large"
      name="close-outline"
    ></ion-icon>
  </div>

  <div
    class="fake-nav"
    *ngIf="storageManager.getFromStorage('isFirstTime')"
    [class.fakeNavDisabled]="sliderIndex === 0"
  >
    <ion-tab-button
      [class.fakeNavHighlighted]="sliderIndex === 1"
      [disabled]="sliderIndex === 0"
    >
      <ion-icon name="calculator"></ion-icon>
      <ion-label>Calculator</ion-label>
    </ion-tab-button>

    <ion-tab-button
      [class.fakeNavHighlighted]="sliderIndex === 2"
      [disabled]="sliderIndex === 0"
    >
      <ion-icon name="document"></ion-icon>
      <ion-label>Recipe</ion-label>
    </ion-tab-button>

    <ion-tab-button
      *ngIf="!isFreemium"
      [class.fakeNavHighlighted]="sliderIndex === 3"
      [disabled]="sliderIndex === 0"
    >
      <ion-icon name="bookmark"></ion-icon>
      <ion-label>Saved</ion-label>
    </ion-tab-button>

    <ion-tab-button
      [class.fakeNavHighlighted]="isFreemium ? sliderIndex === 3 : sliderIndex === 4"
      [disabled]="sliderIndex === 0"
    >
      <ion-icon name="information-circle"></ion-icon>
      <ion-label>Misc</ion-label>
    </ion-tab-button>
  </div>

  <ion-router-outlet *ngIf="!storageManager.getFromStorage('isFirstTime')">
    <div
      class="timer-module"
      [@inOutAnimation]
      *ngIf="timerService.timerModalOpen"
    >
      <div>
        <ion-button
          color="dark"
          class="text-button"
          [disabled]="timerService.timerActive"
          (click)="timerService.inputMinutes()"
          >Set</ion-button
        >
        <ion-button
          color="dark"
          [disabled]="
            timerService.timerActive || !timerService.customTimerAmount
          "
          class="text-button"
          (click)="timerService.runCustomTimerAmount()"
        >
          <span *ngIf="timerService.customTimerAmount">{{
            timerService.customTimerAmount
          }}</span>
          <span *ngIf="!timerService.customTimerAmount">00</span>
        </ion-button>
      </div>

      <div>
        <p class="timer-text" id="timerHTML">{{ timerService.timeAmount }}</p>
      </div>

      <div>
        <ion-button
          color="dark"
          class="text-button"
          (click)="timerService.defaultRunTimer()"
          [disabled]="timerService.timerActive"
          >10</ion-button
        >

        <ion-button
          color="dark "
          *ngIf="!timerService.timerActive"
          (click)="timerService.beginTimer()"
          [disabled]="!timerService.countdown"
          ><ion-icon slot="icon-only" name="play"></ion-icon
        ></ion-button>
        <ion-button
          color="dark"
          *ngIf="timerService.timerActive"
          (click)="timerService.stopTimer(true)"
        >
          <ion-icon slot="icon-only" name="square"></ion-icon>
        </ion-button>
      </div>
    </div>
  </ion-router-outlet>
</ion-app>
