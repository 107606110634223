import {APP_INITIALIZER, NgModule} from '@angular/core';

import { StorageManagerService } from './shared/services/storage-manager.service';

export function initSettings(storageManagerService: StorageManagerService) {
  return () => storageManagerService.init()
}

@NgModule({
  providers: [StorageManagerService, {provide: APP_INITIALIZER, useFactory: initSettings, deps: [StorageManagerService], multi: true}]
})
export class AppInitializerModule {}