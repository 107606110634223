import {
  animate,
  keyframes,
  query,
  stagger,
  style,
  transition,
  trigger,
} from "@angular/animations";

export const leaveEnter = trigger("inOutAnimation", [
  transition(":enter", [
    animate(
      ".3s ease-out",
      keyframes([
        style({ transform: "translateY(-15px)", opacity: 0, offset: 0 }),
        style({ transform: "translateY(0)", opacity: 1, offset: 1 }),
      ])
    ),
  ]),
  transition(":leave", [
    animate(
      ".3s ease-out",
      keyframes([
        style({ transform: "translateY(0)", opacity: 1, offset: 0 }),
        style({ transform: "translateY(-15px)", opacity: 0, offset: 1 }),
      ])
    ),
  ]),
]);

export const leaveEnterReverse = trigger("inOutAnimationReverse", [
  transition(":enter", [
    animate(
      ".3s ease-in-out",
      keyframes([
        style({ transform: "translateY(15px)", opacity: 0, offset: 0 }),
        style({ transform: "translateY(0)", opacity: 1, offset: 1 }),
      ])
    ),
  ])
]);


export const slideFromRight = trigger("slideInFromRight", [
  transition(":enter", [
    animate(
      "0.8s ease-in-out",
      keyframes([
        style({ transform: "translateX(100%)", opacity: 0, offset: 0 }),
        style({ transform: "translateX(0)", opacity: 1, offset: 1 }),
      ])
    ),
  ]),
  transition(":leave", [
    animate(
      "0.8s ease-out",
      keyframes([
        style({ opacity: 1, offset: 0 }),
        style({ opacity: 0, offset: 1 }),
      ])
    ),
  ]),
]);

export const fadeInOut = trigger("fadeInOut", [
  transition(":enter", [
    animate(
      ".5s ease-out",
      keyframes([
        style({ height: 0, opacity: 0, offset: 0 }),
        style({ height: "*", opacity: 1, offset: 1 }),
      ])
    ),
  ]),
  transition(":leave", [
    animate(
      ".5s ease-out",
      keyframes([
        style({ height: "*", opacity: 1, offset: 0 }),
        style({ height: 0, opacity: 0, offset: 1 }),
      ])
    ),
  ]),
])

export const fadeInOutFast = trigger("fadeInOutFast", [
  transition(":enter", [
    animate(
      ".10s",
      keyframes([
        style({ height: 0, opacity: 0, offset: 0 }),
        style({ height: "*", opacity: 1, offset: 1 }),
      ])
    ),
  ]),
  transition(":leave", [
    animate(
      ".10s",
      keyframes([
        style({ height: "*", opacity: 1, offset: 0 }),
        style({ height: 0, opacity: 0, offset: 1 }),
      ])
    ),
  ]),
])

export const staggerEnter =
  // Trigger animation cards array
  trigger("cardAnimation", [
    // Transition from any state to any state
    transition("* => *", [
      // Initially the all cards are not visible
      query(":enter", style({ opacity: 0 }), { optional: true }),
      query(":enter", [
        style({ opacity: 0 }),
        stagger(35, [
          animate(
            "0.35s ease-in-out",
            keyframes([
              style({
                opacity: 0,
                transform: "translateY(-15px)",
                offset: 0,
              }),
              style({ opacity: 1, transform: "translateY(0)", offset: 1 }),
            ])
          ),
        ]),
      ]),
    ]),
  ]);
