import { AfterContentInit, Component, OnInit } from "@angular/core";
import { Platform, AlertController } from "@ionic/angular";
import { ToastController } from "@ionic/angular";
import { MobileAccessibility } from "@awesome-cordova-plugins/mobile-accessibility/ngx";
import { Keyboard } from "@awesome-cordova-plugins/keyboard/ngx";
import { ScreenOrientation } from "@awesome-cordova-plugins/screen-orientation/ngx";
import { StorageManagerService } from "../app/shared/services/storage-manager.service";
import { UtilitiesService } from "./shared/services/utilities.service";
import {
  leaveEnter,
  slideFromRight,
} from "./shared/animations/public-animations";
import { ThemeChangerComponent } from "./shared/theme-changer/theme-changer.component";
import { SplashScreen } from "@capacitor/splash-screen";
import { App } from "@capacitor/app";
import { devMode } from "./shared/objects/feature-flags";

@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
  styleUrls: ["app.component.scss"],
  animations: [slideFromRight, leaveEnter],
})
export class AppComponent implements OnInit, AfterContentInit {
  constructor(
    public storageManager: StorageManagerService,
    public platform: Platform,
    public toastController: ToastController,
    public alertController: AlertController,
    private mobileAccessibility: MobileAccessibility,
    private keyboard: Keyboard,
    private screenOrientation: ScreenOrientation,
    public utilitiesService: UtilitiesService,
    public themeChangerComponent: ThemeChangerComponent
  ) {
    this.initializeApp();
  }
  ngAfterContentInit(): void {
    this.platform.ready().then(async () => {
      if (this.platform.is("hybrid")) {
        await SplashScreen.hide().then(() => {
          this.themeChangerComponent.themeBars();
        });
      }
    });
  }

  sliderIndex = 0;

  async ngOnInit() {
    this.themeChangerComponent.loadThemeInit();

    this.platform.ready().then(() => {
      if (this.platform.is("hybrid")) {
        this.utilitiesService.getVersionNumber();
        if (this.platform.is("tablet") || this.platform.is("ipad")) {
          this.screenOrientation.unlock();
        } else {
          this.screenOrientation.lock(
            this.screenOrientation.ORIENTATIONS.PORTRAIT
          );
        }
        this.utilitiesService.setIsMobile(true);

        this.keyboard.hideFormAccessoryBar(false);
      } else {
        if (devMode) {
          this.utilitiesService.setIsMobile(true);
        } else {
          this.utilitiesService.setIsMobile(false);
        }
      }
      if (this.platform.is("android")) {
        this.mobileAccessibility.usePreferredTextZoom(false);
        document.documentElement.style.setProperty("--header-height", "56px");
      } else {
        document.documentElement.style.setProperty(
          "--header-height",
          "env(safe-area-inset-top) + 45px"
        );
      }
    });

    this.platform.ready().then(() => {
      if (this.platform.is("hybrid")) {
        if (this.storageManager.checkForReview()) {
          this.openReviewModal();
        }

      }
      this.checkLatestNotice();
    });
  }

  checkLatestNotice() {
    if (this.storageManager.checkForLatestNotice()) {
      if (!this.storageManager.getFromStorage('isFirstTime')) { this.openLatestNotice(); }
    }
  }

  async openLatestNotice() {
    const alert = await this.alertController.create({
      header: "Important notice",
      message:
        `There are some big changes coming that will impact your ability to download this app in the future. Check the Misc tab for more information.`,
      buttons: [
        {
          text: "Dismiss",
          cssClass: "secondary",
          handler: async () => {
            this.storageManager.latestNoticeShown();
          },
        },
      ],
    });

    await alert.present();
  }

  async openReviewModal() {
    const alert = await this.alertController.create({
      header: "Rate this app",
      message:
        "If this app has been of value to you, please consider leaving a positive review on the app store. It helps me out tremendously!",
      buttons: [
        {
          text: "No, Thanks",
          role: "cancel",
          cssClass: "secondary",
          handler: async () => {
            const alert = await this.alertController.create({
              header: "Rate this app",
              message: "Can I bug you about it another time?",
              buttons: [
                {
                  text: "No, thanks",
                  role: "cancel",
                  cssClass: "secondary",
                  handler: () => {
                    this.storageManager.reviewDone();
                  },
                },
                {
                  text: "Sure",
                  cssClass: "alert-button-confirm",
                  handler: async () => {
                    this.storageManager.resetReviewDate();
                  },
                },
              ],
            });

            await alert.present();
          },
        },
        {
          text: "Rate Now",
          cssClass: "alert-button-confirm",
          handler: async () => {
            let storeLink =
              "https://play.google.com/store/apps/details?id=pizza.dough.calculator";
            if (!this.platform.is("android")) {
              storeLink =
                "https://apps.apple.com/us/app/pizza-dough-premium/id1597616314";
            }

            window.open(storeLink, "_system", "location=yes");
            this.storageManager.reviewDone();
          },
        },
      ],
    });

    await alert.present();
  }

  updateIndex(e) {
    this.sliderIndex = e.detail[0].activeIndex;
  }

  initializeApp() {
    /* for testing, remove for prod */
    this.utilitiesService.initializeDeepLinkListener();
    this.platform.ready().then(async () => {
      if (this.platform.is("hybrid")) {
        if (!this.storageManager.getFromStorage("completedV1Migration")) {
          this.storageManager.migrateDb();

          this.themeChangerComponent.loadThemeInit();

          setTimeout(() => {
            this.themeChangerComponent.loadThemeInit();
          }, 500);

          setTimeout(() => {
            this.themeChangerComponent.loadThemeInit();
          }, 1000);

          setTimeout(() => {
            this.themeChangerComponent.loadThemeInit();
          }, 2000);
        }

        App.addListener("resume", () => {
          this.themeChangerComponent.themeBars();
        });
      }
    });
  }
}
