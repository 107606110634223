import { Injectable } from "@angular/core";
import { LocalNotifications } from "@capacitor/local-notifications";
import { Vibration } from "@awesome-cordova-plugins/vibration/ngx";
import {
  AlertController,
  PickerColumnOption,
  PickerController,
  Platform,
} from "@ionic/angular";
import { StorageManagerService } from "./storage-manager.service";
import {
  NativeSettings,
  AndroidSettings,
  IOSSettings,
} from "capacitor-native-settings";

@Injectable({
  providedIn: "root",
})
export class TimerService {
  constructor(
    private platform: Platform,
    private storageManager: StorageManagerService,
    private vibration: Vibration,
    public alertController: AlertController,
    private pickerCtrl: PickerController
  ) {}

  timerModalOpen = false;
  customTimerAmount = "";
  timeLeft: string;

  timeAmount = "00:00:00";
  timerActive = false;

  countdown: string;

  thatTimerThing;

  loadCustomTimerAmount() {
    this.customTimerAmount =
      this.storageManager.getFromStorage("customTimerAmount");
  }

  public openTimerModal() {
    this.timerModalOpen = !this.timerModalOpen;
    if (this.timerModalOpen) {
      this.loadCustomTimerAmount();
    }

    if (this.platform.is("android")) {
      document.documentElement.style.setProperty(
        "--header-height",
        this.timerModalOpen ? "112px" : "56px"
      );
    } else {
      document.documentElement.style.setProperty(
        "--header-height",
        this.timerModalOpen
          ? "calc(100px + env(safe-area-inset-top))"
          : "env(safe-area-inset-top) + 45px"
      );
    }
  }

  generatePickerTimes(cap: number) {
    const pickerOptions: PickerColumnOption[] = [];
    for (let index = 0; index < cap + 1; index++) {
      pickerOptions.push({
        text: `${index}`,
        value: `${index}`,
      });
    }

    return pickerOptions;
  }

  async inputMinutes() {
    const minutes = this.generatePickerTimes(99);

    const pickerIndex = this.customTimerAmount
      ? minutes.findIndex(
          (min) => min.value === this.customTimerAmount.split(".")[0]
        )
      : 0;

    const picker = await this.pickerCtrl.create({
      columns: [
        {
          name: "min",
          options: minutes,
          selectedIndex: pickerIndex,
        },
      ],
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
        },
        {
          text: "Custom",
          handler: async () => {
            const alert = await this.alertController.create({
              header: "Enter time in minutes",
              inputs: [
                {
                  name: "value1",
                  type: "number",
                },
              ],
              buttons: [
                {
                  text: "Cancel",
                  role: "cancel",
                  cssClass: "secondary",
                  handler: () => {},
                },
                {
                  text: "Okay",
                  cssClass: "alert-button-confirm",
                  handler: (alertData) => {
                    this.countdown = alertData.value1;
                    this.customTimerAmount = alertData.value1;
                    this.storageManager.saveToStorage(
                      "customTimerAmount",
                      alertData.value1
                    );
                  },
                },
              ],
            });
            await alert.present();
          },
        },
        {
          text: "Set",
          handler: (value) => {
            this.countdown = value.min.value;
            this.customTimerAmount = value.min.value;
            this.storageManager.saveToStorage(
              "customTimerAmount",
              value.min.value
            );
          },
        },
      ],
    });

    await picker.present();
  }

  getMinutesBetweenDates(startDate: Date, endDate: Date) {
    const diff = endDate.getTime() - startDate.getTime();

    return diff / 60000;
  }

  continueTimerFromLastRuntime() {
    this.stopTimer();
    const lastTimerSetEpochString =
      this.storageManager.getFromStorage("lastTimerSetEpoch");
    if (lastTimerSetEpochString === 0) {
      return;
    }

    const lastTimerSetEpoch = new Date(lastTimerSetEpochString);

    const currentTimerEpoch = new Date(Date.now());

    const distanceBetweenTimes = this.getMinutesBetweenDates(
      lastTimerSetEpoch,
      currentTimerEpoch
    );

    const potentialTimeLeft =
      Number(this.storageManager.getFromStorage("lastTimerSetMinutes")) -
      distanceBetweenTimes;

    if (potentialTimeLeft > 0) {
      this.timeLeft = potentialTimeLeft.toString();
      this.countdown = potentialTimeLeft.toString();

      this.beginTimer();
      this.openTimerModal();
    }
  }

  defaultRunTimer() {
    this.timeLeft = "";
    this.countdown = "10";

    this.beginTimer();
  }

  runCustomTimerAmount() {
    this.timeLeft = "";
    this.countdown = this.customTimerAmount;
    this.beginTimer();
  }

  getNumberPrefix(value: number): string {
    return value.toString().length < 2 ? "0" : "";
  }

  async beginTimer() {
    this.platform.ready().then(async () => {
      if (
        this.platform.is("hybrid") &&
        this.storageManager.getFromStorage("askNotificationPermission")
      ) {
        LocalNotifications.checkPermissions().then(async (response) => {
          if (response.display !== "granted") {
            await LocalNotifications.requestPermissions();
            LocalNotifications.checkPermissions().then(async (response) => {
              if (response.display !== "granted") {
                const alert = await this.alertController.create({
                  header: "Notice",
                  message: `Granting this app permission to send you notifications means you can get an alert when your timer finishes. Without it you can still use the timer, but your app will have to stay open to see when it's completed.`,
                  buttons: [
                    {
                      text: "Ignore",
                      cssClass: "secondary",
                      handler: () => {
                        this.timerDisclaimerDialog(true);
                      },
                    },
                    {
                      text: "Don't ask again",
                      cssClass: "secondary",
                      handler: () => {
                        this.storageManager.saveToStorage(
                          "askNotificationPermission",
                          false
                        );
                        this.timerDisclaimerDialog(true);
                      },
                    },
                    {
                      text: "Grant permission",
                      cssClass: "secondary",
                      handler: () => {
                        NativeSettings.open({
                          optionAndroid: AndroidSettings.ApplicationDetails,
                          optionIOS: IOSSettings.App,
                        });
                      },
                    },
                  ],
                });

                await alert.present();
              } else {
                this.timerDisclaimerDialog();
              }
            });
          } else {
            this.timerDisclaimerDialog();
          }
        });
      } else {
        this.timerDisclaimerDialog(true);
      }
    });
  }

  async timerDisclaimerDialog(skip = false): Promise<void> {
    if (skip) {
      this.beginTimerAction();
    } else {
      if (this.storageManager.getFromStorage("timerDisclaimer")) {
        this.beginTimerAction();
      } else {
        const alert = await this.alertController.create({
          header: "Disclaimer",
          message: `Due to restrictions enforced in recent OS updates, the timer may stop running if this app is closed. See more in Misc -> Info.`,
          buttons: [
            {
              text: "I understand",
              cssClass: "alert-button-confirm",
              handler: async () => {
                this.storageManager.saveToStorage("timerDisclaimer", "true");

                if (this.platform.is("android")) {
                  await this.androidTimerDialog();
                } else {
                  this.beginTimerAction();
                }
              },
            },
          ],
        });

        await alert.present();
      }
    }
  }

  async androidTimerDialog(openedFromSettings = false): Promise<void> {
    let messagePrefix = "On Android you can try to remedy this.";
    if (openedFromSettings) {
      messagePrefix =
        "On Android you can try to fix the OS level restrictions that cause the timer to stop running in the background.";
    }

    const alert = await this.alertController.create({
      header: "Timer permissions",
      message: `${messagePrefix} In your system level app settings, do the following: 
      <br />DISABLE "Pause app activity if unused". 
      <br />Then in Alarms & reminders, 
      <br />ENABLE "Allow setting alarms and reminders".
      ${
        openedFromSettings
          ? `<br />And of course, ensure Notifications are enabled for the app.`
          : ""
      }`,
      buttons: [
        {
          text: "Done",
          cssClass: "alert-button-confirm",
          handler: () => {
            if (!openedFromSettings) {
              this.beginTimerAction();
            }
          },
        },
        {
          text: "Take me there",
          cssClass: "alert-button-confirm",
          handler: () => {
            NativeSettings.open({
              optionAndroid: AndroidSettings.ApplicationDetails,
              optionIOS: IOSSettings.App,
            });

            return false;
          },
        },
      ],
    });

    await alert.present();
  }

  beginTimerAction() {
    if (!this.timeLeft) {
      document.getElementById(
        "timerHTML"
      ).innerHTML = `00:${this.countdown}:00`;
    }

    this.timerActive = true;

    this.storageManager.saveToStorage("lastTimerSetEpoch", Date.now());
    this.storageManager.saveToStorage("lastTimerSetMinutes", this.countdown);

    let timerAmount: string;

    if (this.timeLeft) {
      let hms = this.timeLeft; // your input string

      if (hms.includes(":")) {
        let a = hms.split(":"); // split it at the colons

        let hour = +a[0] * 60;
        let minutes = +a[1];
        let seconds = +a[2] / 60;

        const time = hour + minutes + seconds;
        timerAmount = time.toString();
      } else {
        timerAmount = hms;
      }
    } else {
      timerAmount = this.countdown.toString();
    }

    /* '-dup' is there becuase Android Studio complained about there being duplicates */
    let audio = new Audio("../../../assets/newalarmsound-dup.mp3");

    // Set the date we're counting down to
    let countDownDate = new Date(
      new Date().valueOf() + Number(timerAmount) * 60000
    ).getTime();

    if (this.platform.is("android")) {
      LocalNotifications.checkPermissions().then(async (response) => {
        if (response.display === "granted") {
          await LocalNotifications.schedule({
            notifications: [
              {
                title: `Time's up!`,
                body: `Your pizza timer has ended.`,
                id: 1,
                schedule: {
                  at: new Date(
                    new Date().valueOf() + Number(timerAmount) * 60000
                  ),
                  allowWhileIdle: true,
                },
              },
            ],
          });
        }
      });
    }

    // Update the count down every 1 second
    this.thatTimerThing = setInterval(() => {
      // Get today's date and time
      let now = new Date().getTime();

      // Find the distance between now and the count down date
      let distance = countDownDate - now;

      // Time calculations for days, hours, minutes and seconds

      let hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      );
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);

      if (document.getElementById("timerHTML")) {
        if (distance > 0) {
          document.getElementById(
            "timerHTML"
          ).innerHTML = `${this.getNumberPrefix(
            hours
          )}${hours}:${this.getNumberPrefix(
            minutes
          )}${minutes}:${this.getNumberPrefix(seconds)}${seconds}`;
        }

        if (
          (!hours || hours < 0) &&
          (!minutes || minutes < 0) &&
          (!seconds || seconds < 0)
        ) {
          this.timeLeft = "";
        } else {
          this.timeLeft = `${hours}:${minutes}:${seconds}`;
        }

        if (distance < 0) {
          document.getElementById("timerHTML").innerHTML = `Time's up!`;

          this.platform.ready().then(async () => {
            audio.play();
          });

          this.timeDone();
          this.countdown = "";

          clearInterval(this.thatTimerThing);
        }
      }
    }, 100);
  }

  timeDone() {
    this.timerActive = false;
    this.vibration.vibrate(1500);
    this.clearStorageTimer();
  }

  async stopTimer(hard = false) {
    this.timerActive = false;
    clearInterval(this.thatTimerThing);

    this.platform.ready().then(async () => {
      if (this.platform.is("hybrid")) {
        LocalNotifications.checkPermissions().then(async (response) => {
          if (response.display === "granted") {
            LocalNotifications.getPending().then((res) => {
              if (res.notifications.length > 0) {
                LocalNotifications.cancel(res);
              }
            });
          }
        });
      }
    });

    if (hard) {
      this.clearStorageTimer();
    }
  }

  clearStorageTimer() {
    this.storageManager.saveToStorage("lastTimerSetEpoch", 0);
    this.storageManager.saveToStorage("lastTimerSetMinutes", "0");
  }
}
