import { Injectable } from "@angular/core";
import { Storage } from "@ionic/storage-angular";
import { recipeType } from "../interfaces/recipe.interface";
import { nullRecipe, defaultRecipes } from "../objects/public-recipes";
import { Drivers } from "@ionic/storage";
import { SQLitePorter } from "@awesome-cordova-plugins/sqlite-porter";
import { SQLite } from "@awesome-cordova-plugins/sqlite/ngx";

@Injectable({
  providedIn: "root",
})
export class StorageManagerService {
  constructor(private storage: Storage, private sqlite: SQLite) {}

  private storageObject: Object = {};

  private allRecipes = [];

  public currentRecipe: recipeType;

  private defaultStorageObjectValues = {
    decimalPrecision: 3,
    measureType: "grams",
    instructionalView: "detailed",
    instructionalViewHide: false,
    showInfo: false,
    showAdvanced: false,
    isFirstTime: true,
    showGlobalThemeBtn: false,
    theme: "light",
    tableInfo: false,
    keepScreenOn: false,
    showPanDiagram: true,
    defaultCalcUnitType: "uscustomary",
    showReorder: false,
    simpleMode: false,
    showSimpleModeAlert: true,
    pizzaTime: false,
    dateOfInitialization: Date.now(),
    askedToReview: false,
    latestNoticeShown: false,
    completedV1Migration: false,
    detailedNutrition: true,
  };

  async init(): Promise<void> {
    return new Promise(async (resolve) => {
      this.storage = new Storage({
        driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
      });

      await this.storage.create().then(async (interceptStorage) => {
        await interceptStorage
          .forEach((value, key) => {
            // recipeDraft will be handled independently
            if (key === "recipeDraft") {
              return;
            }
            if (value === undefined && this.defaultStorageObjectValues[key]) {
              this.storageObject[key] = this.defaultStorageObjectValues[key];
            } else {
              this.storageObject[key] = value;
            }
          })
          .then(() => {
            Object.keys(this.defaultStorageObjectValues).forEach((key) => {
              if (!this.storageObject.hasOwnProperty(key)) {
                interceptStorage.set(key, this.defaultStorageObjectValues[key]);
                this.storageObject[key] = this.defaultStorageObjectValues[key];
              }
            });
          });
        await this.regetAllRecipes(interceptStorage);

        await this.addSortIds();

        this.sortRecipes();

        await interceptStorage.get("recipeDraft").then((recipe) => {
          if (recipe) {
            this.currentRecipe = recipe;
          } else {
            this.resetCurrentRecipe(interceptStorage);
          }
        });
        resolve();
        return interceptStorage;
      });
    });
  }

  public checkForReview(): boolean {
    if (this.storageObject["askedToReview"]) {
      return false;
    }

    const twoWeeksMilliseconds = 1209600000;

    const initDate = this.storageObject["dateOfInitialization"];
    const currentDate = Date.now();

    if (currentDate - initDate > twoWeeksMilliseconds) {
      return true;
    } else {
      return false;
    }
  }

  public checkForLatestNotice(): boolean {
    if (this.storageObject["latestNoticeShown"]) {
      return false;
    }
    return true;
  }

  public latestNoticeShown(): void {
    this.saveToStorage("latestNoticeShown", true);
  }

  public reviewDone(): void {
    this.saveToStorage("askedToReview", true);
  }

  public resetReviewDate(): void {
    this.saveToStorage("askedToReview", false);
    this.saveToStorage("dateOfInitialization", Date.now());
  }

  public async regetAllRecipes(storage: Storage = this.storage) {
    this.allRecipes = [];
    await storage.forEach((recipe, key) => {
      // dirty check to see if the object even is a recipe
      if (recipe && recipe.approach && key !== "recipeDraft") {
        // backward compatibility
        recipe = this.validateSplitValues(recipe);
        this.allRecipes.push(recipe);
      }
    });
  }

  private async addSortIds(): Promise<void> {
    if (this.allRecipes.some((r) => r.sortId || r.sortId === 0)) {
      let startingInt = Math.max(...this.allRecipes.map((o) => o.sortId)) || 0;

      this.allRecipes.forEach((recipe) => {
        if (recipe.sortId || recipe.sortId === 0) {
          return;
        }

        recipe.sortId = startingInt;
        startingInt++;
      });
    } else {
      let startingInt = 0;

      this.allRecipes.forEach((recipe) => {
        recipe.sortId = startingInt;
        startingInt++;
      });
    }
  }

  public async getTheme(): Promise<string> {
    return new Promise(async (resolve) => {
      this.storage.get("theme").then((themeFromStorageValue) => {
        resolve(themeFromStorageValue);
      });
    });
  }

  public resetCurrentRecipe(storage: Storage = undefined): void {
    let modifiedNullRecipe: recipeType = JSON.parse(JSON.stringify(nullRecipe));
    modifiedNullRecipe.useMetric =
      this.getFromStorage("defaultCalcUnitType") === "metric";

    this.currentRecipe = JSON.parse(JSON.stringify(modifiedNullRecipe));

    if (!storage) {
      this.resetSimpleMode();
    } else {
      storage.set("showSimpleModeAlert", false);
      storage.set("simpleMode", false);
    }
  }

  public saveRecipeDraft(recipe: recipeType, recipeReassignment = true): void {
    this.storage.set("recipeDraft", JSON.parse(JSON.stringify(recipe)));
    if (recipeReassignment) {
      this.currentRecipe = JSON.parse(JSON.stringify(recipe));
    }
  }

  public openExistingRecipe(name: string): void {
    let existingRecipe = JSON.parse(JSON.stringify(defaultRecipes[name]));
    existingRecipe = this.validateSplitValues(existingRecipe);
    /* This key is only required for the recipe selector */
    delete existingRecipe["description"];

    this.currentRecipe = JSON.parse(JSON.stringify(existingRecipe));
    this.resetSimpleMode();
  }

  public resetSimpleMode(): void {
    this.toggleStorageBool("simpleMode", true, false);
    this.toggleStorageBool("showSimpleModeAlert", true, true);
  }

  public async saveToStorage(key: string, value: any): Promise<void> {
    if (value !== undefined) {
      this.storageObject[key] = value;
      await this.storage.set(key, value);
    }
  }

  public remove(key: string): void {
    this.storage.remove(key);
    delete this.storageObject[key];
  }

  public getFromStorage(key: string): any {
    return this.storageObject[key];
  }

  public async getRecipeFromDatabase(requestedKey: string): Promise<any> {
    return this.storage.get(requestedKey);
  }

  public async toggleStorageBool(
    key: string,
    overrideValue = false,
    providedValue = false
  ): Promise<boolean> {
    if (overrideValue) {
      this.storageObject[key] = providedValue;
    } else {
      this.storageObject[key] = !this.storageObject[key];
    }

    this.storage.set(key, this.storageObject[key]);
    return this.storageObject[key];
  }

  public getAllRecipes(): Array<recipeType> {
    return this.allRecipes;
  }

  public reorderRecipes(recipes: recipeType[]): void {
    recipes.forEach((recipe, index) => {
      recipe.sortId = index;
    });
    recipes.forEach((recipe) => {
      this.storage.set(recipe.title, recipe);
    });

    this.allRecipes = this.allRecipes.filter((r) => r !== undefined);

    this.sortRecipes();
  }

  public alphabetizeRecipes(backward = false): void {
    this.allRecipes = this.allRecipes.sort((a, b) =>
      a.title.localeCompare(b.title)
    );

    if (backward) {
      this.allRecipes.reverse();
    }

    this.reorderRecipes(this.allRecipes);
  }

  public sortRecipes(): void {
    this.allRecipes = this.allRecipes.sort((a, b) => a.sortId - b.sortId);
  }

  public validateRecipes(): void {
    this.storage.forEach((recipe, key) => {
      if (
        recipe &&
        recipe.approach &&
        key !== "recipeDraft" &&
        recipe.title !== key
      ) {
        this.storage.remove(key);
        this.storage.set(recipe.title, recipe);
      }
    });
  }

  public deleteRecipe(recipeName: string): void {
    this.allRecipes = this.allRecipes.filter(
      (recipe) => recipe.title !== recipeName
    );
    this.storage.remove(recipeName);
  }

  async addRecipe(newName: string, newRecipe: recipeType) {
    if (!newName) {
      return;
    }

    newRecipe = this.validateSplitValues(newRecipe);

    newRecipe.title = newName;

    this.allRecipes.push(JSON.parse(JSON.stringify(newRecipe)));
    this.storage.set(newName, JSON.parse(JSON.stringify(newRecipe)));

    this.saveToStorage(newName, newRecipe);

    newName = "";

    this.saveToStorage("originalTitle", newRecipe.title);

    this.saveRecipeDraft(JSON.parse(JSON.stringify(newRecipe)));

    return;
  }

  // backward compatibility
  validateSplitValues(newRecipe: recipeType): recipeType {
    if (!newRecipe.flours) {
      newRecipe.flours = nullRecipe.flours;
    }

    if (!newRecipe.waters) {
      newRecipe.waters = nullRecipe.waters;
    }

    if (!newRecipe.flourId) {
      newRecipe.flourId = 0;
    }

    if (!newRecipe.waterId) {
      newRecipe.waterId = 0;
    }

    return newRecipe;
  }

  public async saveRecipe(
    recipeName: string,
    recipe: recipeType
  ): Promise<void> {
    let match = this.allRecipes.find((recipe) => recipe.title === recipeName);
    if (!!match) {
      match = JSON.parse(JSON.stringify(recipe));
    }

    this.storage.set(recipeName, JSON.parse(JSON.stringify(recipe)));

    this.validateRecipes();
    await this.regetAllRecipes(this.storage);
    await this.addSortIds();
  }

  public renameRecipe(
    newName: string,
    oldName: string,
    recipe: recipeType
  ): void {
    this.allRecipes.forEach((recipe) => {
      if (recipe.title === oldName) {
        recipe.title = newName;
      }
    });
    delete this.storageObject[oldName];
    this.storageObject[newName] = recipe;

    this.allRecipes = this.allRecipes.filter(
      (recipe) => recipe.title !== oldName
    );

    this.storage.remove(oldName);
    this.storage.set(newName, JSON.parse(JSON.stringify(recipe)));
  }

  public checkOriginalTitle(): void {
    const storageOriginalTitle = this.getFromStorage("originalTitle");
    if (storageOriginalTitle) {
      this.saveToStorage("originalTitle", storageOriginalTitle);
    } else if (!storageOriginalTitle) {
      this.saveToStorage("originalTitle", this.currentRecipe.title);
    }
  }

  public migrateDb(): void {
    this.sqlite
      .create({
        name: "_ionicstorage",
        location: "default",
      })
      .then((db: any) => {
        SQLitePorter.exportDbToJson(db)
          .then((jsonData: any) => {
            this.migrateDataFromDb(jsonData);
          })
          .catch((error) => {
            console.error(error);
          });
      })
      .catch((error) => {
        console.error(error);
      });
  }

  public async migrateDataFromDb(jsonData: any): Promise<void> {
    const data = jsonData["data"]!["inserts"]!["_ionickv"]!;

    if (!data) {
      this.saveToStorage("completedV1Migration", true);
      return;
    }

    data.forEach((object) => {
      const value = JSON.parse(object.value);
      if (value.approach) {
        if (value.isTemporary === true) {
          this.saveRecipeDraft(value, true);
        } else if (value.isTemporary === false || !value.isTemporary) {
          this.addRecipe(object.key, value);
        }
      } else {
        if (typeof value === "string" || value instanceof String) {
          if (object.value === '"false"') {
            this.saveToStorage(object.key, false);
          } else if (object.value === '"true"') {
            this.saveToStorage(object.key, true);
          } else {
            this.saveToStorage(object.key, value);
          }
        } else {
          this.saveToStorage(object.key, value);
        }
      }
      this.saveToStorage("completedV1Migration", true);
    });
  }
}
