import { StatusBar, Style } from "@capacitor/status-bar";
import { Component } from "@angular/core";
import { PopoverController } from "@ionic/angular";
import { Platform } from "@ionic/angular";
import { StorageManagerService } from "../services/storage-manager.service";
declare const NavigationBar: any;
@Component({
  selector: "app-settings",
  templateUrl: "./theme-changer.component.html",
  styleUrls: ["./theme-changer.component.scss"],
})
export class ThemeChangerComponent {
  constructor(
    private popoverCtrl: PopoverController,
    private platform: Platform,
    private storageManager: StorageManagerService
  ) {}
  themeStyle: string;
  themeAuto: boolean;

  async onDismiss() {
    try {
      await this.popoverCtrl.dismiss();
    } catch (e) {
      //click more than one time popover throws error, so ignore...
    }
  }
  loadThemeInit(onlyNavAndStatusBar = false) {
    this.storageManager.getTheme().then((themeFromStorageValue) => {
      if (themeFromStorageValue) {
        if (!onlyNavAndStatusBar) {
          var element = document.getElementById("body-theme");
          element.classList.remove("light");
          element.classList.remove("dark");
          element.classList.remove("amoled");

          const pizzaTime = this.storageManager.getFromStorage("pizzaTime");
          if (pizzaTime) {
            element.classList.add("pizza-time");
          }

          element.classList.add(themeFromStorageValue);
        }
        this.platform.ready().then(async () => {
          if (this.platform.is("hybrid")) {
            if (themeFromStorageValue === "light") {
              this.styleStatusAndNavByHexString("#ffffff", true);

              return;
            }
            if (themeFromStorageValue === "dark") {
              this.styleStatusAndNavByHexString("#121212", false);

              return;
            }
            if (themeFromStorageValue === "amoled") {
              this.styleStatusAndNavByHexString("#000000", false);

              return;
            }
            return;
          }
        });
      }
    });
  }

  public themeBars(): void {
    /* This is kludge code. Android does not allow navbar to be colored while splash screen is present. That's a bug */
    /* I can't count on all devices to finish loading at the same time, this gives leeway */
    this.loadThemeInit(true);
    setTimeout(() => {
      this.loadThemeInit(true);
    }, 500);

    setTimeout(() => {
      this.loadThemeInit(true);
    }, 1000);

    setTimeout(() => {
      this.loadThemeInit(true);
    }, 2000);
  }

  segmentChanged(value) {
    var element = document.getElementById("body-theme");
    element.classList.remove("light");
    element.classList.remove("dark");
    element.classList.remove("amoled");

    element.classList.add(value);

    this.colorValueChange(value);

    this.popoverCtrl.dismiss();

    this.storageManager.saveToStorage("theme", value);
  }

  segmentChangedtoAuto() {
    this.themeAuto = true;
    // Use matchMedia to check the user preference
    const prefersDark = window.matchMedia("(prefers-color-scheme: dark)");

    toggleDarkTheme(prefersDark.matches);

    // Listen for changes to the prefers-color-scheme media query
    prefersDark.addListener((mediaQuery) =>
      toggleDarkTheme(mediaQuery.matches)
    );

    // Add or remove the "dark" class based on if the media query matches

    function toggleDarkTheme(shouldAdd) {
      document.body.classList.toggle("dark", shouldAdd);
    }
    this.popoverCtrl.dismiss();
  }

  styleStatusAndNavByHexString(colorName, lightNav = false) {
    this.platform.ready().then(async () => {
      if (this.platform.is("hybrid")) {

        await StatusBar.setStyle({
          style: lightNav ? Style.Light : Style.Dark,
        });

        if (this.platform.is("android")) {
          await StatusBar.setBackgroundColor({ color: colorName });
          NavigationBar.backgroundColorByHexString(colorName, lightNav);
        }
      }
    });
  }

  colorValueChange(value) {
    this.themeAuto = false;
    this.platform.ready().then(async () => {
      if (this.platform.is("hybrid")) {
        if (value === "light") {
          this.styleStatusAndNavByHexString("#ffffff", true);
          return;
        }
        if (value === "dark") {
          this.styleStatusAndNavByHexString("#121212", false);
          return;
        }
        if (value === "amoled") {
          this.styleStatusAndNavByHexString("#000000", false);
          return;
        }
        return;
      }
    });
  }
}
