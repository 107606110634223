import { Injectable, NgZone } from "@angular/core";
import { AlertController, NavController, Platform, PopoverController } from "@ionic/angular";
import { Subject } from "rxjs";
import { ThemeChangerComponent } from "../../shared/theme-changer/theme-changer.component";
import { App, URLOpenListenerEvent } from "@capacitor/app";
import { StorageManagerService } from "./storage-manager.service";
import { KeepAwake } from "@capacitor-community/keep-awake";

@Injectable({
  providedIn: "root",
})
export class UtilitiesService {
  constructor(
    public platform: Platform,
    private popoverController: PopoverController,
    public alertController: AlertController,
    private storageManager: StorageManagerService,
    private zone: NgZone,
    private nav: NavController
  ) {}

  isMobile: boolean = false;  
  URLloaded = new Subject<void>();
  urlString = "";

  initializeDeepLinkListener(): void {
    this.platform.ready().then(async () => {
      if (this.platform.is("hybrid")) {
        App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
          this.zone.run(() => {
            // Example url: https://beerswift.app/tabs/tab2
            // slug = /tabs/tab2
            const slug = event.url.split(".com/").pop();
            if (slug) {
              this.handleDeepLink(slug);
            }
          });
        });
      }
    });
  }

  
  async handleDeepLink(returnURLObject) {
    const alert = await this.alertController.create({
      header: "Please confirm",
      message: `Would you like to open that link in this app? This will overwrite any unsaved changes.`,
      buttons: [
        {
          text: "Cancel",
          role: "cancel",
          cssClass: "secondary",
          handler: () => {},
        },
        {
          text: "Yes",
          cssClass: "alert-button-confirm",
          handler: async () => {
            this.urlString = returnURLObject.toString();

            this.nav.navigateRoot("tabs/tab1");

            this.URLloaded.next();
          },
        },
      ],
    });
    await alert.present();
  }

  screenAllowSleep() {
    this.platform.ready().then(async () => {
      if (this.platform.is("hybrid")) {
        await KeepAwake.allowSleep();
      }
    });
  }

  async screenKeepAwake() {
    this.platform.ready().then(async () => {
      if (this.platform.is("hybrid")) {
        await KeepAwake.keepAwake();
      }
    });
  }

  setIsMobile(value: boolean): void {
    this.isMobile = value;
  }

  getIsMobile(): boolean {
    return this.isMobile;
  }

  getVersionNumber(): string | null {
    if (this.platform.is("hybrid")) {
      App.getInfo().then((appInfo) => {
        const appVersionString = appInfo.version;

        this.storageManager.saveToStorage("appversion", appVersionString);
        return appVersionString;
      });
    } else {
      return null;
    }
  }

  async presentThemePopover(ev: Event) {
    const popover = await this.popoverController.create({
      component: ThemeChangerComponent,
      event: ev,
      translucent: true,
      size: "auto",
    });
    return await popover.present();
  }

}
