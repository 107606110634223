<ion-content>
  <ion-button color="light" (click)="segmentChanged('light')">
    Light
  </ion-button>
  <ion-button color="medium" (click)="segmentChanged('dark')">
    Dark
  </ion-button>
  <ion-button color="dark" (click)="segmentChanged('amoled')">
    Amoled
  </ion-button>
</ion-content>
